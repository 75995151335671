body {
  background: rgb(21, 21, 21);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-main {
  color: rgb(255, 239, 200);
}

.color-alt {
  color: rgb(92, 183, 54);
}

.color-alt-2 {
  color: rgb(197, 75, 57);
}

.bg-main {
  background: rgb(255, 239, 200);
}

.bg-alt {
  background: rgb(92, 183, 54);
}

.bg-alt-2 {
  background: rgb(197, 75, 57);
}
